<template>
    <div id="app">

        <div class="step1" v-show="step1Show">
            <div class="txb2">
                <div class="bank_sub_title">
                    <span><font class="green"></font>重置支付密码</span>
                </div>

                

                <div class="txb2 mgb_0">
                    <van-field
                            v-model="info.mobile"
                            required
                            type="tel"
                            label="预留手机"
                            maxlength="11"
                            placeholder="请输入预留手机号"
                    />
                </div>
                <div class="txb2 mgb_0">
                    <div style="width:78%">
                        <van-field
                            v-model="info.code"
                            required
                            type="tel"
                            label="验证码"
                            maxlength="6"
                            placeholder="请输入验证码"
                            @click="showKeyboard3=true"
                        />
                    </div>
                    
                    <a href="###" class="get_code">获取验证码</a>
                </div>

                <div class="txb2 mgb_0">
                    <van-field
                            v-model="info.password"
                            required
                            readonly
                            maxlength="6"
                            type="password"
                            label="设置新密码"
                            placeholder="请点击输入新密码"
                            @click="showKeyboard1=true"
                    />
                </div>
                <div class="txb2 ">
                    <van-field
                            readonly
                            maxlength="6"
                            v-model="info.repassword"
                            required
                            type="password"
                            label="确认新密码"
                            placeholder="请再次输入以确认"
                            @click="showKeyboard2=true"
                    />
                </div>
            
            <div class="bank_btn ">
                <button class="green" @click="check">
                    确认
                </button>
            </div>
        </div>
        </div>
        


        

        <!-- 数字键盘 -->
        <van-number-keyboard
                v-model="info.password"
                :show="showKeyboard1"
                @blur="showKeyboard1 = false"
        />

        <!-- 数字键盘 -->
        <van-number-keyboard
                v-model="info.repassword"
                :show="showKeyboard2"
                @blur="showKeyboard2 = false"
        />

        <!-- 数字键盘 -->
        <van-number-keyboard
                v-model="info.code"
                :show="showKeyboard3"
                @blur="showKeyboard3 = false"
        />
    </div>
</template>


<style scoped>
    #app {
        background: #f8f8f8;
        height: 100vh;
        overflow: scroll;
        position: relative;
        z-index: 2000;
    }


    ::v-deep .van-uploader__upload {
        margin: 0 !important;
        width: 40vw !important;
        height: 23vw !important;
        background: url(../assets/images/photo_upload.png) center center ;
        background-size: 100% 100%;
    }

    ::v-deep .bank_upload ul li:nth-child(2) .van-uploader__upload {
        margin: 0 !important;
        width: 40vw !important;
        height: 23vw !important;
        background: url(../assets/images/photo_upload2.png) center center ;
        background-size: 100% 100%;
    }

    ::v-deep .van-uploader__preview {
        margin: 0 !important;
    }

    ::v-deep .van-uploader__preview-image {
        width: 40vw !important;
        height: 23vw !important;
        overflow: hidden;
    }

    ::v-deep .van-uploader__upload-icon {
        display: none;
    }

    /* ::v-deep .van-field__label {
        width: 9em !important;
    } */

    ::v-deep .van-cell__value {
        /* text-align: left !important; */
    }

</style>

<script>
    import Vue from 'vue'
    import {post} from "@/utils/http";
    import {getRealLength} from "@/utils/util";
    import Notify from "vant/es/notify";
    import {Dialog} from 'vant';

    export default {
        data() {
            return {

                step1Show: true,
                step2Show: false,
                step3Show: false,



                showKeyboard1: false,
                showKeyboard2: false,
                showKeyboard3:false,

                loading: false,
                info: {
                    mobile: "",
                    code:"",
                    password: "",
                    repassword: "",

                },
            };
        },
        methods: {
            check() {

                if (!this.info.mobile) {
                    Notify({type: "danger", message: '请输入手机号'});
                    return;
                } else if (!this.info.code) {
                    Notify({type: "danger", message: '请输入验证码'});
                    return;
                } else if (!this.info.password) {
                    Notify({type: "danger", message: '请设置密码'});
                    return;
                } else if (!this.info.repassword) {
                    Notify({type: "danger", message: '请确认密码'});
                    return;
                } else if (this.info.password != this.info.repassword) {
                    Notify({type: "danger", message: '两次密码不一致'});
                    return;
                }

                // 去除前后空格
                let that = this;
                Object.keys(that.info).forEach(function (key) {
                    that.info[key] = that.stringTrim(that.info[key])
                });


            },
            
        }
    };
</script>
